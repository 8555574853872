import { Listbox } from '@headlessui/react';
import { oneLine } from 'common-tags';

import { ChevronDown } from '@/icons';

import type { FunctionComponent } from 'react';
import type { SelectButtonProps } from './types';

/**
 * Primary UI component for user interaction
 */
export const SelectButton: FunctionComponent<SelectButtonProps> = ({
  open,
  error,
  label,
  placeholder,
  className = '',
  theme = 'primary',
}: SelectButtonProps) => (
  <Listbox.Button
    className={oneLine`relative flex h-12 w-full appearance-none px-3 text-left outline-none focus:ring-0 ${
      theme === 'product'
        ? 'u-body flex-row-reverse items-center gap-x-1.5 rounded-sm border border-primary-900 bg-white !normal-case !text-primary-400 focus:border-typo-primary focus:!text-primary-600 sm:text-sm'
        : ''
    } ${
      theme === 'primary'
        ? 'u-body flex-row-reverse items-center justify-between border border-primary-300 bg-white text-base !normal-case tracking-normal !text-primary-300 focus:border-typo-primary focus:!text-primary-600'
        : ''
    } ${
      theme === 'secondary'
        ? '!u-actions !u-actions--link h-8 cursor-pointer flex-row items-end justify-between gap-x-2 rounded-2xl border border-solid border-primary-900 bg-transparent px-4 py-2 !text-primary-900 outline-none hover:border-primary-600 hover:text-primary-600 md:px-6'
        : ''
    } ${
      theme === 'tertiary'
        ? 'flex-row-reverse items-center justify-center gap-x-0.5 bg-bg-tertiary text-typo-alternative'
        : ''
    } ${open && theme === 'secondary' ? 'rounded-b-none border-b-0' : ''} ${
      error
        ? '!border-status-error !text-status-error focus:!text-status-error'
        : ''
    } ${className} `}
  >
    <ChevronDown
      className={oneLine` ${open ? '-rotate-180' : ''} h-4 w-4 shrink-0 transition ${
        theme === 'product' || theme === 'tertiary' ? 'text-secondary-500' : ''
      } ${theme === 'secondary' ? 'static order-2 text-primary-900' : ''} `}
    />
    {label && label.label ? label.label : placeholder}
  </Listbox.Button>
);
