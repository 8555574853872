import { Listbox } from '@headlessui/react';
import { oneLine } from 'common-tags';

import type { FunctionComponent } from 'react';
import type { SelectOptionProps } from './types';

/**
 * Primary UI component for user interaction
 */
export const SelectOption: FunctionComponent<SelectOptionProps> = ({
  options,
  disabled = false,
  reverse,
  theme = 'primary',
}: SelectOptionProps) => (
  <Listbox.Options
    className={oneLine`absolute z-30 max-h-[250px] overflow-auto ${theme === 'product' ? 'overflow-x-clip bg-white' : ''} ${theme === 'primary' ? 'w-full bg-white' : ''} ${theme === 'secondary' ? 'rounded-b-2x1 w-full rounded-b-2xl bg-bg-primary' : ''} ${
      theme === 'tertiary'
        ? 'w-full bg-bg-tertiary text-center text-typo-tertiary'
        : ''
    } ${reverse ? 'bottom-full' : ''} `}
    unmount={false}
  >
    {
      // TODO: Añadir tipos
      options.map((value: any, index: number) => (
        <Listbox.Option
          key={index}
          value={value.value}
          disabled={disabled}
          className={oneLine`cursor-pointer ${
            theme === 'product'
              ? 'u-body u-body--s border-b border-primary-200 border-b-primary-200 px-6 py-4 text-center hover:bg-primary-900 hover:text-white'
              : ''
          } ${
            theme === 'primary'
              ? 'u-body u-body--m z-auto border-b border-primary-200 border-b-primary-200 px-6 py-4 hover:bg-primary-900 hover:text-white'
              : ''
          } ${
            theme === 'secondary'
              ? 'u-actions u-actions--link h-8 w-auto border border-t-0 border-solid border-primary-900 px-4 py-2 text-primary-900 hover:border-primary-600 hover:bg-transparent hover:text-primary-600 md:px-6 [&:first-child]:border-t [&:last-child]:rounded-b-2xl'
              : ''
          } ${
            theme === 'tertiary'
              ? 'w-full bg-bg-tertiary px-6 py-4 text-center text-typo-tertiary'
              : ''
          }`}
        >
          {value.label}
        </Listbox.Option>
      ))
    }
  </Listbox.Options>
);
